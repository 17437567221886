import { useEffect } from 'react';

const ASU = () => {
    useEffect(() => {
        window.location.href = 'https://search.asu.edu/profile/4295566';
    }, []);

    return null;
};

export default ASU;