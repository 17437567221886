import { useEffect } from 'react';

const Login= () => {
    useEffect(() => {
        window.location.href = 'https://internship.aranish.codes/login';
    }, []);

    return null;
};

export default Login;